import { APP_CONFIGS } from "../constants";
import { IRequest } from "../interfaces/authentication.interface";
import { IFaqSection, IFaqsResponse } from "../interfaces/faqs.interface";
import { strapiRequest } from "./requests.service";

export const getFaqs = async (locale: string): Promise<IRequest<IFaqSection[]>> => {
    try {
        const query = `
            query Faqs($locale: I18NLocaleCode, $filters: ComponentFaqFaqSectionFiltersInput) {
                faq(locale: $locale) {
                    title
                    faqsSections(filters: $filters) {
                        faqItems {
                            body
                            title
                        }
                    }
                }
            }
            `;

        const variables = {
            locale,
            filters: {
                platforms: {
                    key: {
                        eq: APP_CONFIGS.key
                    }
                }
            }
        };

        const response = await strapiRequest.post<IFaqsResponse>(`/graphql`, {
            operationName: "Faqs",
            query,
            variables
        });

        return { success: true, data: response.data.data.faq?.faqsSections ?? [] };
    } catch (error: any) {
        return { success: false, data: [] as any };
    }
};
