import { Link, useLocation } from "react-router-dom";
import Logo from "../Logo/Logo";
import { routes } from "../../shared/router/routes";
import { useContext } from "react";
import { TranslationContext } from "../../shared/providers/translation/translation.provider";

function Navbar() {
    const location = useLocation();
    const { translate } = useContext(TranslationContext);
    const menu = [
        {
            icon: "home",
            href: routes.HOME.path,
            label: translate("common.home", "Home")
        },
        {
            icon: "explore",
            href: routes.EXPLORE.path,
            label: translate("common.explore", "Explore")
        },
        // {
        //     icon: "edit",
        //     href: routes.STYLEGUIDE.path,
        //     label: translate("common.styleguide", "Styleguide")
        // },
        // {
        //     icon: "document",
        //     href: routes.MY_ACCOUNT.path,
        //     label: translate("common.account", "Account")
        // },
        {
            icon: "help",
            href: routes.FAQS.path,
            label: translate("common.faqs", "FAQs")
        },
        // {
        //     icon: "document",
        //     href: routes.TRANSLATIONS.path,
        //     label: translate("common.languages", "Languages")
        // },
        {
            icon: "user--multiple",
            href: routes.CONTACTS.path,
            label: translate("common.contact_feedback", "Contact & Feedback")
        }
    ];

    return (
        <nav className="navbar">
            <Logo isWhite />
            <ul className="menu">
                {menu.map((item, i) => (
                    <li key={i} className={item.href === location.pathname ? "is_active" : ""}>
                        <Link to={item.href}>
                            <i className={`icon icon-24 icon-${item.icon}`}></i> {item.label}
                        </Link>
                    </li>
                ))}
            </ul>
        </nav>
    );
}

export default Navbar;
